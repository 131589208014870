<template>
  <div class="ui grid container-4" id="ctctushc">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1>Contact <span>Us</span></h1>
          <p>For more questions,  you may reach us through email, message us on Facebook, give us a call, or schedule a walk-in appointment.</p>

          <div class="contactus">
            <h4>Email</h4>
            <p>You can email us at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a></p>

            <h4>Call</h4>
            <p>You can call us directly at <span>(02) 8888-TAYO</span> to talk to us about your concern or schedule an appointment to meet with us. Our office address is at 26th Floor, Antel Global Bldg, Ortigas Center, Pasig City.</p>

            <h4>Social Media</h4>
            <p>You can directly message us on our social media!</p>

            <div class="fb">
              <a href="https://www.facebook.com/TayoCash-PH"><img src="@/assets/fb.svg"><span>TayoCash PH</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="column div-2">
        <div class="right">
          <h1>Downloadable <span>Forms</span></h1>
          <p>Find the forms that you need here.</p>

          <div class="download-content">
            <h4>Partner/Merchant Forms</h4>
            <ul>
              <li><a href="https://assets.prod.tayocash.com/forms/partner_merchant_enrollment_form.pdf" download target="_blank">Partner & Merchant Enrollment Form</a></li>
              <li><a href="https://assets.prod.tayocash.com/forms/partner_merchant_branch_enrollment_form.pdf" download target="_blank">Partner & Merchant Branch Enrollment Form</a></li>
              <li><a href="https://assets.prod.tayocash.com/forms/partner_merchant_change_request_form.pdf" download target="_blank">Partner & Merchant Change Request Form</a></li>
            </ul>
          </div>

          <div class="download-content">
            <h4>Complaint and Dispute Forms</h4>
            <ul>
              <li><a href="https://assets.prod.tayocash.com/forms/complaint_form.pdf" download target="_blank">Complaint Form</a></li>
              <li><a href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Transaction Dispute Form</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content4'
}
</script>

<style scoped lang='scss'></style>
